import * as React from "react";
import {
    Text,
    TextField,
    IconButton,
    CommandButton,
    Stack,
    getTheme,
    DirectionalHint,
    IContextualMenuProps,
    FontIcon,
    PrimaryButton,
} from "@fluentui/react";
import { toast } from "react-toastify";
import { encodeLabelString, getFieldKeyFromLabel, getDynamicTableRowNumberFromLabel } from "utils/customModel";
import { FieldLocation, switchTableFieldsSubType, updateTableLabel } from "store/customModel/customModel";
import { Field, ObjectField, FieldType, Label, VisualizationHint, HeaderType, LabelType } from "models/customModels";
import MessageModal from "view/components/messageModal/messageModal";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "store";
import { getWithAutoRetry, postWithAutoRetry } from "apis/requestHelper";
import { ToastContainer } from "react-toastify";
import Select from "react-select";

import "./tableLabelItem.scss";
import "react-toastify/dist/ReactToastify.css";

type TableLabels = { [labelName: string]: Label };

export interface ITableLabelItemProps {
    field: Field;
    tableLabels: TableLabels;
    definition: ObjectField;
    onDeleteField: (tableFieldKey, fieldKey, fieldLocation) => Promise<void>;
    onInsertField: (tableFieldKey, fieldKey, index, fieldLocation) => Promise<void>;
    onRenameField: (tableFieldKey, oldName, newName, fieldLocation) => Promise<void>;
    onDeleteLabel: (label) => Promise<void>;
    onClickCell: (labelName: string) => Promise<void>;
    onItemMouseEnter: (labelName: string) => void;
    onItemMouseLeave: () => void;
    fileName: string;
    isChecked: boolean;
    getAndSetLabels: () => Promise<void>;
    onCheckChanged: (event: any) => void;
}

export interface ITableLabelItemState {
    isConfirmModalOpen: boolean;
    isConfirmModalLoading: boolean;
    isAdjustModalOpen: boolean;
    isRowConfirmModalOpen: boolean;
    isRowConfirmModalLoading: boolean;
    confirmOperation?: "delete" | "rename";
    dynamicRows: number;
    deletingField?: { fieldKey: string; fieldLocation: FieldLocation };
    renamingField?: { headerType: HeaderType; fieldKey: string; fieldLocation: FieldLocation };
    insertingField?: { headerType: HeaderType; index: number; fieldLocation: FieldLocation };
    newFieldName?: string;
    item: number;
    newRow: number;
    isLoading: boolean;
    delCol: string;
    delRowStart: number;
    delRowEnd: number;
    delAll: boolean;
    delName: boolean;
    reduction: boolean;
    page_style: string[];
    pages: number[];
    isAdjustBorder: boolean;
}

export class TableLabelItem extends React.PureComponent<
    ITableLabelItemProps & ConnectedProps<typeof connector>,
    ITableLabelItemState
> {
    private renameTextFieldRef: React.RefObject<any>;
    private insertTextFieldRef: React.RefObject<any>;

    private subTypeOptions = [
        { key: FieldType.String, text: FieldType.String },
        { key: FieldType.Number, text: FieldType.Number },
        { key: FieldType.Date, text: FieldType.Date },
        { key: FieldType.Time, text: FieldType.Time },
        { key: FieldType.Integer, text: FieldType.Integer },
    ];

    constructor(props) {
        super(props);
        this.state = {
            isConfirmModalOpen: false,
            isConfirmModalLoading: false,
            isAdjustModalOpen: false,
            isRowConfirmModalOpen: false,
            isRowConfirmModalLoading: false,
            dynamicRows: 1,
            item: 0,
            newRow: 1,
            isLoading: false,
            delCol: "",
            delRowStart: 1,
            delRowEnd: 1,
            delAll: false,
            delName: false,
            reduction: false,
            page_style: [],
            pages: [],
            isAdjustBorder: false,
        };
        this.renameTextFieldRef = React.createRef();
        this.insertTextFieldRef = React.createRef();
    }

    private isEnteringRename: boolean = false;

    private headerButtonStyles = {
        root: {
            fontWeight: "600",
            padding: 12,
            height: "100%",
        },
    };
    public componentDidMount() {
        if (this.props.field.fieldType === FieldType.Array) {
            if (this.props.fileName) {
                if (localStorage.getItem(`${this.props.fileName}_isChecked`) !== null) {
                    this.setState({ isAdjustBorder: true });
                }
                const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
                const apis = `${serverUrl}/getItemNum/${this.props.fileName}`;
                getWithAutoRetry(apis).then((res) => {
                    if (res["data"]["code"] === 200) {
                        this.setState({ item: res.data.item_num });
                        this.setDynamicRows();
                    } else {
                        toast.error("取得項次數量有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
                    }
                    this.setState({ newRow: this.state.dynamicRows });
                });
                const api_page = `${serverUrl}/getChangePage/${this.props.fileName}`;
                getWithAutoRetry(api_page).then((res) => {
                    if (res["data"]["code"] === 200) {
                        this.setState({ page_style: res.data.data[0], pages: res.data.data[1] });
                    } else {
                        toast.error("取得項次頁碼有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
                    }
                });
            }
        }
    }
    public componentDidUpdate(prevProps) {
        const { isChecked, getAndSetLabels } = this.props;
        if (prevProps.isChecked !== isChecked) {
            const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
            const apis = `${serverUrl}/numberConversion/${this.props.fileName}`;
            postWithAutoRetry(apis, { isChecked: isChecked }).then((res) => {
                getAndSetLabels().then(() => this.setState({ isLoading: false }));
            });
            this.setState({ isLoading: true });
        } else if (
            (isChecked && this.state.delAll) ||
            (isChecked && this.state.delName) ||
            (isChecked && this.state.reduction)
        ) {
            const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
            const apis = `${serverUrl}/numberConversion/${this.props.fileName}`;
            postWithAutoRetry(apis, { isChecked: isChecked }).then((res) => {
                getAndSetLabels().then(() => this.setState({ isLoading: false }));
                if (this.state.delAll) {
                    this.setState({ delAll: false });
                }
                if (this.state.delName) {
                    this.setState({ delName: false });
                }
                if (this.state.reduction) {
                    this.setState({ reduction: false });
                }
            });
            this.setState({ isLoading: true });
        }
    }
    //     const { tableLabels, labelError } = this.props;
    //     if (
    //         this.getDynamicRows(tableLabels) !== this.getDynamicRows(prevProps.tableLabels) ||
    //         labelError !== prevProps.labelError
    //     ) {
    //         // Make sure that dynamicRows in state is aligned with labels in store, if they are not aligned, pick max value between them as current dynamicRows.
    //         this.setState({
    //             dynamicRows: this.state.item,
    //         });
    //     }
    // }

    // private getRowNumbers = (tableLabels: TableLabels) =>
    //     Object.keys(tableLabels).map((labelName) => parseInt(labelName.split("/")[1]) + 1);

    // private getDynamicRows = (tableLabels: TableLabels) => {
    //     const rowNumbers = this.getRowNumbers(tableLabels);
    //     return rowNumbers.length > 0 ? Math.max(...rowNumbers) : 1;
    // };

    private setDynamicRows = () => {
        this.setState({ dynamicRows: this.state.item });
    };

    private addDynamicRow = async () => {
        this.setState((prevState) => ({ dynamicRows: prevState.dynamicRows + 1, newRow: prevState.newRow + 1 }));
    };

    private deleteDynamicRow = async () => {
        this.setState((prevState) => ({ dynamicRows: prevState.dynamicRows - 1, newRow: prevState.newRow - 1 }));
    };

    private getInsertHeader = () => {
        return (
            <th key="insert" className="general-header">
                <Stack horizontalAlign="center" verticalAlign="center">
                    <TextField
                        className="insert-textfield"
                        onKeyDown={this.handleInsertKeyDown}
                        onBlur={this.handleInsertBlur}
                        onGetErrorMessage={this.handleGetInsertErrorMessage}
                        styles={{ fieldGroup: { width: 120 }, root: { padding: 12 } }}
                        componentRef={this.insertTextFieldRef}
                        autoComplete="off"
                    />
                </Stack>
            </th>
        );
    };

    private handleMouseEnter = (labelName: string) => {
        const { tableLabels, onItemMouseEnter } = this.props;

        if (tableLabels && tableLabels[labelName]) {
            onItemMouseEnter(labelName);
        }
    };

    private handleMouseLeave = () => {
        this.props.onItemMouseLeave();
    };

    private getTableCell = (label: string) => {
        const { tableLabels } = this.props;
        const isLabelTypeRegion = tableLabels[label]?.labelType === LabelType.Region;
        const labelTextValue = tableLabels[label]?.value.map((v) => v.text).join(" ");
        const theme = getTheme();
        const renderCellContent = isLabelTypeRegion ? (
            <FontIcon className="label-item-icon" iconName="SingleColumnEdit" />
        ) : (
            labelTextValue
        );

        return (
            <>
                {renderCellContent}
                {renderCellContent && (
                    <IconButton
                        className="delete-cell-button"
                        iconProps={{ iconName: "Cancel" }}
                        styles={{
                            icon: { fontSize: 11 },
                            root: { height: 20, width: 20 },
                            rootHovered: { backgroundColor: theme.palette.themeLighter },
                            rootPressed: { backgroundColor: theme.palette.themeLight },
                        }}
                        onClick={(e) => this.handleDeleteLabel(e, label)}
                    />
                )}
            </>
        );
    };

    private handleTableFieldsSubTypeChangeByHeader = (headerField: Field, targetType: FieldType): void => {
        const { switchTableFieldsSubType, field } = this.props;
        switchTableFieldsSubType({
            tableFieldKey: field.fieldKey,
            newType: targetType,
            headerField,
        });
    };

    private getSubTypeMenu = (headerField: Field) => {
        return this.subTypeOptions.map((option) => {
            return {
                ...option,
                canCheck: true,
                checked: headerField.fieldType === option.key,
                onClick: () => this.handleTableFieldsSubTypeChangeByHeader(headerField, option.key),
            };
        });
    };

    private getTableHeader = (
        headerType: HeaderType,
        headerField: Field,
        index: number,
        fieldLocation: FieldLocation
    ) => {
        const { field: rawField, definition } = this.props;
        const { fieldKey } = headerField;
        const field = rawField as ObjectField;
        const { renamingField } = this.state;
        const onRenameClick = () => this.handleRenameClick(headerType, fieldKey, fieldLocation);
        const onInsertClick = () => this.handleInsertClick(headerType, index, fieldLocation);
        const onDeleteClick = () => this.handleDeleteClick(fieldKey, fieldLocation);
        const isDeleteDisabled =
            fieldLocation === FieldLocation.field ? field.fields.length <= 1 : definition.fields.length <= 1;
        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: headerType === HeaderType.column ? "renameColumn" : "renameRow",
                    text: headerType === HeaderType.column ? "重新命名欄" : "Rename row",
                    onClick: onRenameClick,
                },
                {
                    key: headerType === HeaderType.column ? "insertColumn" : "insertRow",
                    text: headerType === HeaderType.column ? "插入欄" : "Insert row",
                    onClick: onInsertClick,
                },
                {
                    key: headerType === HeaderType.column ? "deleteColumn" : "deleteRow",
                    text: headerType === HeaderType.column ? "刪除欄" : "Delete row",
                    onClick: onDeleteClick,
                    disabled: isDeleteDisabled,
                },
                // {
                //     key: "subType",
                //     text: "Sub type",
                //     subMenuProps: {
                //         items: this.getSubTypeMenu(headerField),
                //     },
                //     disabled:
                //         (field.visualizationHint === VisualizationHint.Horizontal &&
                //             headerType === HeaderType.column) ||
                //         (field.visualizationHint === VisualizationHint.Vertical && headerType === HeaderType.row),
                // },
            ],
            directionalHint: DirectionalHint.bottomRightEdge,
        };
        const isRenaming = renamingField?.fieldKey === fieldKey && renamingField?.headerType === headerType;

        return (
            <th
                key={fieldKey}
                className="general-header"
                onDoubleClick={() => this.handleRenameClick(headerType, fieldKey, fieldLocation)}
            >
                {isRenaming ? (
                    <Stack horizontalAlign="center" verticalAlign="center">
                        <TextField
                            className="rename-textfield"
                            defaultValue={fieldKey}
                            onKeyDown={this.handleRenameKeyDown}
                            onBlur={this.handleRenameBlur}
                            onGetErrorMessage={this.handleGetRenameErrorMessage}
                            styles={{ fieldGroup: { width: 100 }, root: { padding: 12 } }}
                            componentRef={this.renameTextFieldRef}
                            autoComplete="off"
                        />
                    </Stack>
                ) : (
                    <CommandButton text={fieldKey} menuProps={menuProps} styles={this.headerButtonStyles} />
                )}
            </th>
        );
    };

    private replaceTableRowNumberFromLabel = (label: Label, replacement: number): string => {
        const strings = label.label.split("/");
        strings[1] = replacement.toString();
        return strings.join("/");
    };

    private handleDynamicTableRowInsert = (tableFieldKey: string, rowNumber: number) => {
        const { tableLabels, updateTableLabel } = this.props;
        const tableLabelsValues = Object.values(tableLabels);
        const labelsLessThanRowNumber = tableLabelsValues.filter(
            (label) => getDynamicTableRowNumberFromLabel(label) <= rowNumber
        );
        const labelsGreaterThanRowNumber = tableLabelsValues
            .filter((tableLabel) => getDynamicTableRowNumberFromLabel(tableLabel) > rowNumber)
            .map((tableLabel) => ({
                ...tableLabel,
                label: this.replaceTableRowNumberFromLabel(
                    tableLabel,
                    getDynamicTableRowNumberFromLabel(tableLabel) + 1
                ),
            }));
        const newLabel = [...labelsLessThanRowNumber, ...labelsGreaterThanRowNumber];
        updateTableLabel({
            tableFieldKey,
            newLabel,
        }).then(() => {
            this.updateData();
        });
        this.addDynamicRow();
    };

    private handleDynamicTableRowDelete = (tableFieldKey: string, rowNumber: number) => {
        const { tableLabels, updateTableLabel } = this.props;
        const tableLabelsValues = Object.values(tableLabels);
        const labelsLessThanRowNumber = tableLabelsValues.filter(
            (tableLabel) => getDynamicTableRowNumberFromLabel(tableLabel) < rowNumber
        );
        const labelsGreaterThanRowNumber = tableLabelsValues
            .filter((tableLabel) => getDynamicTableRowNumberFromLabel(tableLabel) > rowNumber)
            .map((tableLabel) => ({
                ...tableLabel,
                label: this.replaceTableRowNumberFromLabel(
                    tableLabel,
                    getDynamicTableRowNumberFromLabel(tableLabel) - 1
                ),
            }));
        const newLabel = [...labelsLessThanRowNumber, ...labelsGreaterThanRowNumber];
        updateTableLabel({
            tableFieldKey,
            newLabel,
        }).then(() => {
            this.updateData();
        });
        this.deleteDynamicRow();
    };

    private getDynamicTableRowHeader = (currentRow: number, isShowPage: boolean, page: number) => {
        const { tableLabels } = this.props;
        const firstTableLabelValue = Object.values(tableLabels)[0];
        const tableFieldKey = firstTableLabelValue ? getFieldKeyFromLabel(firstTableLabelValue) : "";
        const onDeleteClick = () => this.handleDynamicTableRowDelete(tableFieldKey, currentRow);
        const onInsertClick = () => this.handleDynamicTableRowInsert(tableFieldKey, currentRow);
        const isDeleteDisabled = this.state.dynamicRows <= 1;

        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: "insertRow",
                    text: "向下插入列",
                    onClick: onInsertClick,
                },
                {
                    key: "deleteRow",
                    text: "刪除此列",
                    onClick: onDeleteClick,
                    disabled: isDeleteDisabled,
                },
            ],
            directionalHint: DirectionalHint.bottomRightEdge,
        };

        return (
            <th key={0} className="general-header">
                <CommandButton text={`${currentRow + 1}`} menuProps={menuProps} styles={this.headerButtonStyles} />
                {isShowPage ? (
                    <div style={{ fontSize: "14px", color: "gray", fontStyle: "italic" }}>Page:{page}</div>
                ) : (
                    <div></div>
                )}
            </th>
        );
    };

    private getDynamicTableHeader = () => {
        const { insertingField } = this.state;
        const columns = this.props.definition.fields;
        const headers = columns.map((column, index) =>
            this.getTableHeader(HeaderType.column, column, index, FieldLocation.definition)
        );
        if (insertingField && insertingField.headerType === HeaderType.column) {
            headers.splice(insertingField.index, 0, this.getInsertHeader());
        }

        return (
            <tr>
                <th className="empty-header"></th>
                {headers}
            </tr>
        );
    };

    private getFixedTableHeader = () => {
        const { field: rawField, definition } = this.props;
        const { insertingField } = this.state;
        const field = rawField as ObjectField;
        const isFixedRow = field.visualizationHint === VisualizationHint.Horizontal;
        const fieldLocation = isFixedRow ? FieldLocation.field : FieldLocation.definition;
        const columns = isFixedRow ? field.fields : definition.fields;
        const headers = columns.map((column, index) =>
            this.getTableHeader(HeaderType.column, column, index, fieldLocation)
        );
        if (insertingField && insertingField.headerType === HeaderType.column) {
            headers.splice(insertingField.index, 0, this.getInsertHeader());
        }
        return (
            <tr>
                <th className="empty-header"></th>
                {headers}
            </tr>
        );
    };

    private getDynamicTable = () => {
        const { field, definition } = this.props;
        const { insertingField, page_style, pages } = this.state;
        const columns = definition.fields;
        const tableBody: JSX.Element[] = [];
        let index = 0;
        if (page_style.length > 0) {
            for (let row = 0; row < this.state.dynamicRows; row++) {
                let isShowPage = false;
                let cellStyle = {};
                let page = 1;
                const tableRow: JSX.Element[] = [];
                if (page_style[index] === row.toString() && index < page_style.length) {
                    if (page_style[0] === "0") {
                        cellStyle = {};
                    } else {
                        cellStyle = { borderTop: "2.5px solid #7e6e6e" };
                    }
                    page = pages[index];
                    index = index + 1;
                    isShowPage = true;
                }
                for (let column = 0; column < columns.length + 1; column++) {
                    if (column === 0) {
                        // Row header.
                        tableRow.push(this.getDynamicTableRowHeader(row, isShowPage, page));
                    } else {
                        const columnName = encodeLabelString(columns[column - 1].fieldKey);
                        const fieldKey = encodeLabelString(field.fieldKey);
                        const label = `${fieldKey}/${row}/${columnName}`;
                        tableRow.push(
                            <td
                                role="gridcell"
                                key={column}
                                className="table-cell"
                                onClick={() => this.handleTableCell(label)}
                                onMouseEnter={() => this.handleMouseEnter(label)}
                                onMouseLeave={() => this.handleMouseLeave()}
                                style={cellStyle}
                            >
                                {this.getTableCell(label)}
                            </td>
                        );
                    }
                }
                if (insertingField && insertingField.headerType === HeaderType.column) {
                    tableRow.splice(insertingField.index + 1, 0, <td key="insert" className="table-cell"></td>);
                }

                tableBody.push(<tr key={row}>{tableRow}</tr>);
            }
        }
        return tableBody;
    };

    private handleTableCell = async (label) => {
        await this.props.onClickCell(label);
        this.updateData();
    };

    private getFixedTable = () => {
        const { field: rawField, definition } = this.props;
        const { insertingField } = this.state;
        const field = rawField as ObjectField;
        const isFixedRow = field.visualizationHint === VisualizationHint.Horizontal;
        const fieldLocation = isFixedRow ? FieldLocation.definition : FieldLocation.field;
        const columns = isFixedRow ? field.fields : definition.fields;
        const rows = isFixedRow ? definition.fields : field.fields;
        const tableBody: JSX.Element[] = [];

        rows.forEach((row, rowIndex) => {
            const tableRow: JSX.Element[] = [];

            for (let column = 0; column < columns.length + 1; column++) {
                if (column === 0) {
                    // Row header.
                    tableRow.push(this.getTableHeader(HeaderType.row, row, rowIndex, fieldLocation));
                } else {
                    const columnName = encodeLabelString(columns[column - 1].fieldKey);
                    const rowName = encodeLabelString(row.fieldKey);
                    const fieldKey = encodeLabelString(field.fieldKey);
                    const label = isFixedRow
                        ? `${fieldKey}/${columnName}/${rowName}`
                        : `${fieldKey}/${rowName}/${columnName}`;
                    tableRow.push(
                        <td
                            role="gridcell"
                            key={column}
                            className="table-cell"
                            onClick={() => this.handleTableCell(label)}
                            onMouseEnter={() => this.handleMouseEnter(label)}
                            onMouseLeave={() => this.handleMouseLeave()}
                        >
                            {this.getTableCell(label)}
                        </td>
                    );
                }
            }
            if (insertingField && insertingField.headerType === HeaderType.column) {
                // +1 for the column of row header.
                tableRow.splice(insertingField.index + 1, 0, <td key="insert" className="table-cell"></td>);
            }

            tableBody.push(<tr key={rowIndex}>{tableRow}</tr>);
        });

        if (insertingField && insertingField.headerType === HeaderType.row) {
            const emptyCells = new Array(columns.length)
                .fill(null)
                .map((_, index) => <td key={index} className="table-cell"></td>);
            const insertRow = (
                <tr key="insert">
                    {this.getInsertHeader()}
                    {emptyCells}
                </tr>
            );
            tableBody.splice(insertingField.index, 0, insertRow);
        }

        return tableBody;
    };

    private handleInsertClick = (headerType: HeaderType, index: number, fieldLocation: FieldLocation) => {
        this.setState({ insertingField: { headerType, index: index + 1, fieldLocation } });
        //TODO: temporarily using setTimeout for TextField losing focus issue. This should be refactored with a extracted TextField component to handle focus in componentDidMount
        setTimeout(() => {
            this.insertTextFieldRef.current.focus();
        }, 0);
    };

    private handleRenameClick = (headerType: HeaderType, fieldKey: string, fieldLocation: FieldLocation) => {
        this.setState({ renamingField: { headerType, fieldKey, fieldLocation } });
        //TODO: temporarily using setTimeout for TextField losing focus issue. This should be refactored with a extracted TextField component to handle focus in componentDidMount
        setTimeout(() => {
            this.renameTextFieldRef.current.focus();
        }, 0);
    };

    private handleRenameField = async () => {
        const { field, onRenameField } = this.props;
        const { renamingField, newFieldName } = this.state;
        const { fieldKey, fieldLocation } = renamingField!;
        this.setState({ isConfirmModalLoading: true });
        await onRenameField(field.fieldKey, fieldKey, newFieldName, fieldLocation);
        this.setState({
            isConfirmModalLoading: false,
            isConfirmModalOpen: false,
            confirmOperation: undefined,
            renamingField: undefined,
            newFieldName: undefined,
        });
    };

    private handleDeleteClick = (fieldKey: string, fieldLocation: FieldLocation) => {
        this.setState({
            isConfirmModalOpen: true,
            confirmOperation: "delete",
            deletingField: { fieldKey, fieldLocation },
        });
    };

    private handleDeleteField = async () => {
        const { field, onDeleteField } = this.props;
        const { deletingField } = this.state;
        const { fieldKey, fieldLocation } = deletingField!;
        this.setState({ isConfirmModalLoading: true });
        await onDeleteField(field.fieldKey, fieldKey, fieldLocation);
        this.setState({
            isConfirmModalLoading: false,
            isConfirmModalOpen: false,
            confirmOperation: undefined,
            deletingField: undefined,
        });
    };

    private handleRenameKeyDown = (event) => {
        const { renamingField } = this.state;
        const hasError = this.handleGetRenameErrorMessage(event.target.value) !== undefined;
        const isSameName = event.target.value === renamingField!.fieldKey;
        const isEmpty = !event.target.value;

        if (event.key === "Enter" && (isSameName || isEmpty)) {
            this.setState({ renamingField: undefined });
            return;
        }

        if (event.key === "Escape") {
            this.setState({ renamingField: undefined });
            return;
        }

        if (event.key === "Enter" && !hasError) {
            this.isEnteringRename = true;
            this.handleRenameEnter(event.target.value);
        }
    };

    private handleRenameEnter = (value: string) => {
        this.setState({ isConfirmModalOpen: true, confirmOperation: "rename", newFieldName: value });
    };

    private handleRenameBlur = () => {
        if (this.isEnteringRename) {
            this.isEnteringRename = false;
            return;
        }

        this.setState({ renamingField: undefined });
    };

    private handleInsertKeyDown = (event) => {
        const hasError = this.handleGetInsertErrorMessage(event.target.value) !== undefined;
        const isEmpty = !event.target.value;

        if (event.key === "Enter" && isEmpty) {
            this.setState({ insertingField: undefined });
            return;
        }

        if (event.key === "Escape") {
            this.setState({ insertingField: undefined });
            return;
        }

        if (event.key === "Enter" && !hasError) {
            this.handleInsertField(event.target.value);
        }
    };

    private handleInsertField = async (fieldKey: string) => {
        const { field, onInsertField } = this.props;
        const { insertingField } = this.state;
        const { index, fieldLocation } = insertingField!;
        await onInsertField(field.fieldKey, fieldKey, index, fieldLocation);
        this.setState({ insertingField: undefined });
    };

    private handleInsertBlur = () => {
        this.setState({ insertingField: undefined });
    };

    private handleGetInsertErrorMessage = (value: string) => {
        const { fieldLocation } = this.state.insertingField!;

        if (this.isDuplicateFieldKey(value, fieldLocation)) {
            return "The field already exists.";
        } else {
            return undefined;
        }
    };

    private handleConfirmModalClose = () => {
        this.setState({ isConfirmModalOpen: false });
    };

    private handleGetRenameErrorMessage = (value: string) => {
        const { fieldKey, fieldLocation } = this.state.renamingField!;

        if (fieldKey === value) {
            // Nothing changed.
            return undefined;
        } else if (this.isDuplicateFieldKey(value, fieldLocation)) {
            return "The field already exists.";
        } else {
            return undefined;
        }
    };

    private updateData = () => {
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const api_page = `${serverUrl}/getChangePage/${this.props.fileName}`;
        getWithAutoRetry(api_page)
            .then((res) => {
                if (res["data"]["code"] === 200) {
                    this.setState({ page_style: res.data.data[0], pages: res.data.data[1] });
                } else {
                    toast.error("取得項次頁碼有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
                }
            })
            .catch((response) => {
                console.log(response.data);
            });
    };

    private handleDeleteLabel = async (event, label: string) => {
        event.stopPropagation();
        await this.props.onDeleteLabel(label);
        this.updateData();
    };

    private handleConfirmRowModalClose = () => {
        this.setState({ isRowConfirmModalOpen: false });
    };

    private isDuplicateFieldKey = (fieldKey: string, fieldLocation: FieldLocation) => {
        const { field: rawField, definition } = this.props;
        const field = rawField as ObjectField;
        return fieldLocation === FieldLocation.field
            ? field.fields.some((f) => f.fieldKey === fieldKey)
            : definition.fields.some((f) => f.fieldKey === fieldKey);
    };

    private remove_name = () => {
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const apis = `${serverUrl}/deleteName/${this.props.fileName}`;
        getWithAutoRetry(apis).then((res) => {
            if (res["data"]["code"] === 200) {
                if (res["data"]["message"] === "清除貨物名稱中...") {
                    this.props
                        .getAndSetLabels()
                        .then(() => this.setState({ isLoading: false, delName: true, page_style: ["-1"] }));
                    this.setState({ isLoading: true });
                } else {
                    toast.success(res["data"]["message"], { autoClose: 100, position: toast.POSITION.TOP_CENTER });
                }
            } else {
                toast.error("清除貨物名稱有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
            }
        });
    };

    private remove_all = () => {
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const apis = `${serverUrl}/deleteAll/${this.props.fileName}`;
        getWithAutoRetry(apis).then((res) => {
            if (res["data"]["code"] === 200) {
                if (res["data"]["message"] === "清除表格中...") {
                    this.props.getAndSetLabels().then(() => {
                        this.setState({ isLoading: false, delAll: true, page_style: ["-1"], isAdjustBorder: false });
                        localStorage.removeItem(`${this.props.fileName}_isChecked`);
                    });
                    this.setState({ isLoading: true });
                } else {
                    toast.success(res["data"]["message"], {
                        autoClose: 100,
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            } else {
                toast.error("清除表格有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
            }
        });
    };

    private deleteFile = () => {
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const apis = `${serverUrl}/deleteJson/${this.props.fileName}`;
        const api_num = `${serverUrl}/getItemNum/${this.props.fileName}`;
        const api_page = `${serverUrl}/getChangePage/${this.props.fileName}`;
        getWithAutoRetry(apis).then((res) => {
            if (res["data"]["code"] === 200) {
                getWithAutoRetry(api_num).then((res2) => {
                    if (res2["data"]["code"] === 200) {
                        getWithAutoRetry(api_page).then((res3) => {
                            if (res3["data"]["code"] === 200) {
                                this.props.getAndSetLabels().then(() => {
                                    this.setState({
                                        isLoading: false,
                                        reduction: true,
                                        newRow: res2.data.item_num,
                                        dynamicRows: res2.data.item_num,
                                        page_style: res3.data.data[0],
                                        pages: res3.data.data[1],
                                        isAdjustBorder: false,
                                    });
                                    localStorage.removeItem(`${this.props.fileName}_isChecked`);
                                });
                            } else {
                                toast.error("取得項次頁碼有誤", {
                                    autoClose: 500,
                                    position: toast.POSITION.TOP_CENTER,
                                });
                            }
                        });
                    } else {
                        toast.error("取得項目有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
                    }
                });
                if (!this.state.isLoading) {
                    this.setState({ isLoading: true });
                }
            } else {
                toast.error("還原表格有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
            }
        });
    };

    private InsertNewRow = (row) => {
        if (row > 1000) {
            toast.error("輸入項次不得超過1000", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
        } else if (row <= 0) {
            toast.error("輸入項次不得為0", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
        } else if (row < this.state.item) {
            this.setState({ isRowConfirmModalOpen: true });
        } else {
            toast.success("已更新表格", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
            this.setState({ dynamicRows: row });
        }
    };

    private handleRow = () => {
        this.setState({ dynamicRows: this.state.newRow });
        toast.success("已更新表格", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
        this.setState({ isRowConfirmModalOpen: false });
    };

    private adjustBorder = (bool) => {
        this.setState({ isAdjustBorder: bool, isLoading: true });
        if (bool === true) {
            localStorage.setItem(`${this.props.fileName}_isChecked`, bool.toString());
        } else {
            localStorage.removeItem(`${this.props.fileName}_isChecked`);
        }
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const apis = `${serverUrl}/getJson/${this.props.fileName}?isChecked=${bool}`;
        const api_num = `${serverUrl}/getItemNum/${this.props.fileName}`;
        const api_page = `${serverUrl}/getChangePage/${this.props.fileName}`;
        getWithAutoRetry(apis).then((res) => {
            if (res["data"]["code"] === 200) {
                getWithAutoRetry(api_num).then((res2) => {
                    if (res2["data"]["code"] === 200) {
                        getWithAutoRetry(api_page).then((res3) => {
                            if (res3["data"]["code"] === 200) {
                                this.props.getAndSetLabels().then(() => {
                                    this.setState({
                                        isLoading: false,
                                        newRow: res2.data.item_num,
                                        dynamicRows: res2.data.item_num,
                                        page_style: res3.data.data[0],
                                        pages: res3.data.data[1],
                                    });
                                    toast.success("調整邊框完成", {
                                        autoClose: 500,
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                });
                            } else {
                                toast.error("取得項次頁碼有誤", {
                                    autoClose: 500,
                                    position: toast.POSITION.TOP_CENTER,
                                });
                            }
                        });
                    } else {
                        toast.error("取得項目有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
                    }
                });
                if (!this.state.isLoading) {
                    this.setState({ isLoading: true });
                }
            } else {
                this.setState({ isLoading: false });
                toast.error("調整邊框有誤", { autoClose: 500, position: toast.POSITION.TOP_CENTER });
            }
        });
    };

    private handleChangeCheck = (event) => {
        if (event.target.checked === false) {
            this.setState({ isAdjustModalOpen: true });
        } else {
            this.adjustBorder(true);
        }
    };

    private onAdjustModalClick = () => {
        this.adjustBorder(false);
        this.setState({ isAdjustModalOpen: false });
    };

    private handleAdjustModalClose = () => {
        this.setState({ isAdjustModalOpen: false });
    };

    private FullScreenLoading = () => {
        return (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 9999,
                }}
            >
                <div className="three-body">
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                </div>
            </div>
        );
    };

    private DeleteRow = (delCol, delRowStart, delRowEnd, newRow) => {
        if (delCol === "") {
            toast.error("未選擇刪除欄位", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        } else if (delRowStart > newRow || delRowEnd > newRow || delRowStart > delRowEnd) {
            toast.error("請輸入正確的範圍", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        } else if (delRowStart === 0 || delRowEnd === 0) {
            toast.error("輸入項次不得為0", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
        } else {
            const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
            const apis = `${serverUrl}/deleteRow/${this.props.fileName}`;
            postWithAutoRetry(apis, {
                delCol: delCol,
                delRowStart: delRowStart,
                delRowEnd: delRowEnd,
            }).then((res: any) => {
                if (res.data.code === 200) {
                    if (res.data.message === "欄位清除中...") {
                        this.props.getAndSetLabels().then(() => {
                            this.updateData();
                            this.setState({ isLoading: false });
                        });
                        this.setState({ isLoading: true });
                    } else {
                        toast.success(res.data.message, { autoClose: 100, position: toast.POSITION.TOP_CENTER });
                    }
                } else {
                    toast.error(res.data.message, { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
                }
            });
        }
    };

    public render() {
        const { field } = this.props;
        const {
            isRowConfirmModalOpen,
            isRowConfirmModalLoading,
            isAdjustModalOpen,
            isAdjustBorder,
            newRow,
            isLoading,
            delRowStart,
            delRowEnd,
            delCol,
            isConfirmModalOpen,
            isConfirmModalLoading,
            confirmOperation,
            deletingField,
            renamingField,
            newFieldName,
        } = this.state;
        const isDynamicTable = field.fieldType === FieldType.Array;
        const tableHeader = isDynamicTable ? this.getDynamicTableHeader() : this.getFixedTableHeader();
        const table = isDynamicTable ? this.getDynamicTable() : this.getFixedTable();
        const confirmRowModalTitle = "確認更改項次數？";
        const confirmRowModalMessage = <Text>您輸入的項次數小於原本表格預設項次數，請再次確認是否更改項次數</Text>;
        const onConfirmRowModalClick = this.handleRow;
        const confirmModalTitle = confirmOperation === "delete" ? "Delete Field" : "Rename Field";
        const confirmModalMessage =
            confirmOperation === "delete" ? (
                <Text>
                    您確定要刪除 <b>{deletingField?.fieldKey}</b>？所有標籤和區域也都將被刪除。
                </Text>
            ) : (
                <Text>
                    您確定要重新命名 <b>{renamingField?.fieldKey}</b> 為 <b>{newFieldName}</b>
                    ？所有標籤和區域也都將被重新命名。
                </Text>
            );
        const onConfirmModalClick = confirmOperation === "delete" ? this.handleDeleteField : this.handleRenameField;
        // 將所有欄位名稱放入選項
        const options = tableHeader.props.children[1].map((child) => ({
            value: child.key,
            label: child.key,
        }));
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                width: 150,
                fontSize: "14px",
            }),
            control: (provided) => ({
                ...provided,
                width: 150,
                fontSize: "14px",
            }),
        };
        const handleChangeOption = async (option) => {
            this.setState({ delCol: option.value });
        };

        return (
            <Stack className="table-label-item" grow={1} styles={{ root: { alignItems: "center" } }}>
                {isLoading && <this.FullScreenLoading />}
                <ToastContainer />
                <Stack
                    horizontal
                    styles={{
                        root: {
                            border: "3px solid #0078d4",
                            borderRadius: "10px",
                            padding: "10px",
                            alignItems: "center",
                            marginBottom: "10px",
                            width: "90%",
                        },
                    }}
                >
                    <PrimaryButton styles={{ root: { width: "20%", fontSize: "14px" } }} onClick={this.remove_name}>
                        <i className="far fa-eraser" style={{ marginRight: "5px" }}></i>
                        <b>清除貨物名稱</b>
                    </PrimaryButton>
                    <PrimaryButton
                        styles={{
                            root: {
                                width: "20%",
                                marginLeft: "5px",
                                marginRight: "5px",
                                fontSize: "14px",
                                minWidth: "50px",
                            },
                        }}
                        onClick={this.deleteFile}
                    >
                        <i className="far fa-reply" style={{ marginRight: "5px" }}></i>
                        <b>還原初始表格</b>
                    </PrimaryButton>
                    <PrimaryButton
                        styles={{
                            root: {
                                width: "16%",
                                marginRight: "5px",
                                fontSize: "14px",
                                minWidth: "50px",
                            },
                        }}
                        onClick={this.remove_all}
                    >
                        <i className="fal fa-table" style={{ marginRight: "5px" }}></i>
                        <b>清除表格</b>
                    </PrimaryButton>
                    <div style={{ borderLeft: "2px solid #ADADAD", height: "110%", marginRight: "5px" }} />
                    <label htmlFor="row" style={{ fontSize: "14px", marginLeft: "5px" }}>
                        <b>總項次數：</b>
                    </label>
                    <input
                        type="number"
                        id="row"
                        min="1"
                        max="100"
                        value={newRow}
                        onChange={(e) => this.setState({ newRow: Number(e.target.value) })}
                        style={{ width: "5%", height: "50%", marginRight: "10px" }}
                    />
                    <PrimaryButton
                        onClick={() => {
                            this.InsertNewRow(newRow);
                        }}
                        style={{ fontSize: "14px", minWidth: "30px", fontWeight: "1px", marginRight: "5px" }}
                    >
                        <i className="far fa-pencil" style={{ marginRight: "5px" }}></i>
                        <b>更改</b>
                    </PrimaryButton>
                    <div style={{ borderLeft: "2px solid #ADADAD", height: "110%", marginRight: "5px" }} />
                    <Stack>
                        <Stack.Item style={{ display: "flex", alignItems: "center" }}>
                            <b style={{ fontSize: "14px", marginRight: "5px" }}>數字轉換</b>
                            <label htmlFor="check" style={{ marginRight: "3px" }} className="switch">
                                <span style={{ visibility: "hidden" }}>.</span>
                                <input
                                    id="check"
                                    type="checkbox"
                                    checked={this.props.isChecked}
                                    onChange={this.props.onCheckChanged}
                                />
                                <span className="slider round"></span>
                            </label>
                        </Stack.Item>
                    </Stack>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                        <b style={{ fontSize: "14px", marginRight: "5px" }}>調整欄位</b>
                        <label htmlFor="check2" className="switch">
                            <span style={{ visibility: "hidden" }}>.</span>
                            <input
                                id="check2"
                                type="checkbox"
                                checked={isAdjustBorder}
                                onChange={this.handleChangeCheck}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </Stack>

                <Stack
                    horizontal
                    styles={{
                        root: {
                            border: "3px solid 	#FF0000",
                            borderRadius: "10px",
                            padding: "8px",
                            alignItems: "center",
                            marginBottom: "10px",
                            justifyContent: "center",
                            zIndex: 9,
                        },
                    }}
                >
                    <label htmlFor="rows" style={{ fontSize: "14px" }}>
                        <b>清除欄位內容：</b>
                    </label>
                    <Select options={options} styles={customStyles} onChange={handleChangeOption} />
                    <label htmlFor="rows" style={{ marginLeft: "10px", fontSize: "14px", marginRight: "5px" }}>
                        <b>從</b>
                    </label>
                    <input
                        type="number"
                        id="row1"
                        min="1"
                        max={newRow}
                        value={delRowStart}
                        onChange={(e) => this.setState({ delRowStart: Number(e.target.value) })}
                        style={{ width: "6%", height: "50%", marginRight: "5px" }}
                    />
                    <label htmlFor="rows" style={{ fontSize: "14px" }}>
                        <b>項 ~ 第</b>
                    </label>
                    <input
                        type="number"
                        id="row2"
                        min="1"
                        max={newRow}
                        value={delRowEnd}
                        onChange={(e) => this.setState({ delRowEnd: Number(e.target.value) })}
                        style={{ width: "6%", height: "50%", marginLeft: "5px", marginRight: "5px" }}
                    />
                    <label htmlFor="rows" style={{ fontSize: "14px", marginRight: "10px" }}>
                        <b>項</b>
                    </label>
                    <PrimaryButton
                        onClick={() => {
                            this.DeleteRow(delCol, delRowStart, delRowEnd, newRow);
                        }}
                        style={{ fontSize: "14px", minWidth: "20px", fontWeight: "1px", backgroundColor: "red" }}
                    >
                        <i className="far fa-eraser" style={{ marginRight: "5px" }}></i>
                        <b>清除</b>
                    </PrimaryButton>
                </Stack>
                <Stack className="table-body-container" grow={1} disableShrink>
                    <table className="table-body">
                        <thead>{tableHeader}</thead>
                        <tbody>{table}</tbody>
                    </table>
                </Stack>
                <MessageModal
                    isOpen={isRowConfirmModalOpen}
                    isLoading={isRowConfirmModalLoading}
                    title={confirmRowModalTitle}
                    body={<Text variant="medium">{confirmRowModalMessage}</Text>}
                    actionButtonText="是"
                    rejectButtonText="否"
                    onActionButtonClick={onConfirmRowModalClick}
                    onClose={this.handleConfirmRowModalClose}
                />
                <MessageModal
                    isOpen={isConfirmModalOpen}
                    isLoading={isConfirmModalLoading}
                    title={confirmModalTitle}
                    body={<Text variant="medium">{confirmModalMessage}</Text>}
                    actionButtonText="Yes"
                    rejectButtonText="No"
                    onActionButtonClick={onConfirmModalClick}
                    onClose={this.handleConfirmModalClose}
                />
                <MessageModal
                    isOpen={isAdjustModalOpen}
                    title="確認是否取消調整邊框？"
                    body={<Text variant="medium">請問您是否確認要取消調整邊框，取消後文件與表格將回到初始狀態</Text>}
                    actionButtonText="Yes"
                    rejectButtonText="No"
                    onActionButtonClick={this.onAdjustModalClick}
                    onClose={this.handleAdjustModalClose}
                />
            </Stack>
        );
    }
}
const mapState = (state: ApplicationState) => ({
    labelError: state.customModel.labelError,
});
const mapDispatch = {
    switchTableFieldsSubType,
    updateTableLabel,
};

const connector = connect(mapState, mapDispatch);

export default connector(TableLabelItem);
