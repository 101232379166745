import { constants } from "consts/constants";
import { Definitions, Field, Labels } from "models/customModels";
import { StorageProvider } from "providers/storageProvider";
import { IStorageProvider } from "providers/storageProvider";
import { IAssetService } from "./assetService";
import { putWithAutoRetry } from "apis/requestHelper";

export class CustomModelAssetService implements IAssetService {
    private storageProvider: IStorageProvider;
    private fieldsSchema: string = constants.fieldsSchema;
    private labelsSchema: string = constants.labelsSchema;

    constructor() {
        this.storageProvider = new StorageProvider();
    }

    /**
     * Fetch all documents labels.json.
     * @param documents - The documents to be fetched.
     */
    public async fetchAllDocumentLabels(documentNames: string[]): Promise<Labels> {
        const fetchPromises = documentNames.map((documentName) =>
            this.storageProvider.readText(`${documentName}${constants.labelFileExtension}`, true)
        );
        const documentLabelsList = await Promise.all(fetchPromises);
        const result = {};
        documentLabelsList.forEach((documentLabels, index) => {
            result[documentNames[index]] = documentLabels ? JSON.parse(documentLabels).labels : [];
        });
        return result;
    }

    /**
     * Update fields.json.
     * @param fields - The updated fields.
     * @param definitions - The updated definitions.
     */
    public async updateFields(fields: Field[], definitions: Definitions): Promise<void> {
        //取得目前網址查詢參數
        const url = new URL(window.location.href);
        const file = url.searchParams.get("file");
        const fieldsJson = JSON.stringify({ $schema: this.fieldsSchema, fields, definitions }, null, "\t");
        const fieldsFile = `${file}.${constants.fieldsFile}`;
        await this.storageProvider.writeText(fieldsFile, fieldsJson);
    }

    /**
     * Update labels.json for target documents.
     * @param labels - A mapping of document name and its labels.
     */
    public async updateDocumentLabels(labels: Labels, optionalParam?: string): Promise<void> {
        const promises = Object.entries(labels).map(async ([documentName, labels]) => {
            //const blobName = `${documentName}${constants.labelFileExtension}`;
            const url = new URL(window.location.href);
            const file = url.searchParams.get("file");
            const test = await this.storageProvider.readText(`${file}${constants.labelFileExtension}`, true);
            const getPromise = await Promise.resolve(test);
            const getLabelsJson = JSON.parse(getPromise ?? "");
            const fields = getLabelsJson.fields;
            const headers = getLabelsJson.headers;
            const fields_mapping = getLabelsJson.fields_mapping;
            // if (labels.length === 0) {
            //     // Delete label.json if there's no labels for this document.
            //     return this.storageProvider.deleteFile(blobName, true);
            // }
            // const labelsJson = JSON.stringify(
            //     { $schema: this.labelsSchema, document: documentName, labels },
            //     null,
            //     "\t"
            // );
            if (optionalParam) {
                const content = JSON.stringify(
                    { $schema: this.labelsSchema, document: documentName, fields, labels, headers, fields_mapping },
                    null,
                    "\t"
                );
                const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
                const api = `${serverUrl}/files/${documentName}${constants.labelFileExtension}`;
                return putWithAutoRetry(api, {
                    content,
                }).then(() => {
                    console.log("PUT done");
                });
            } else {
                const labelsJson = JSON.stringify(
                    { $schema: this.labelsSchema, document: documentName, labels },
                    null,
                    "\t"
                );
                return this.storageProvider
                    .writeText(`${documentName}${constants.labelFileExtension}`, labelsJson)
                    .then(() => {
                        console.log("PUT done original");
                    });
            }
        });
        await Promise.all(promises);
    }
}
