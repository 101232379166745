import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    Stack,
    IconButton,
    DirectionalHint,
    IButtonStyles,
    PrimaryButton,
    TextField,
    FontWeights,
    mergeStyleSets,
    getTheme,
    IIconProps,
    Modal,
    IDragOptions,
    ContextualMenu,
} from "@fluentui/react";
import { getWithAutoRetry, postWithAutoRetry } from "apis/requestHelper";
import { ApplicationState } from "store";
import {
    addField,
    addTableField,
    switchSubType,
    deleteField,
    deleteTableField,
    renameField,
    renameTableField,
    insertTableField,
    deleteLabelByField,
    deleteLabelByLabel,
    FieldLocation,
    assignLabel,
    setHideInlineLabelMenu,
    setFields,
} from "store/customModel/customModel";
import { getFieldKeyFromLabel } from "utils/customModel";
import {
    FieldType,
    FieldFormat,
    Field,
    Label,
    ObjectField,
    TableType,
    HeaderType,
    ArrayField,
} from "models/customModels";
import FieldCallout from "./components/fieldCallout";
import TableLabelItem from "./components/tableLabelItem";
// import CreateTableModal from "./components/createTableModal";
import LabelList from "./components/labelList";
import { setHoveredLabelName } from "store/canvas/canvas";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./labelPane.scss";

export interface ILabePaneState {
    isFieldCalloutOpen: boolean;
    // isCreateTableModalOpen: boolean;
    isDescriptionModalOpen: boolean;
    showAllFields: boolean;
    createFieldType?: FieldType;
    tableFieldKey?: string;
    organize: string;
    unit: string;
    country: string;
    isChecked: boolean;
    repeat: boolean;
    tableCellNum: number;
}

interface ILabelPaneProps {
    isTablePaneOpen: boolean;
    setIsTablePaneOpen: (state: boolean) => void;
    fileName: string;
    isValid: boolean;
    getAndSetLabels: () => Promise<void>;
}

export class LabelPane extends React.PureComponent<ILabelPaneProps & ConnectedProps<typeof connector>, ILabePaneState> {
    private fieldOptions;
    constructor(props) {
        super(props);
        this.state = {
            isFieldCalloutOpen: false,
            isDescriptionModalOpen: false,
            // isCreateTableModalOpen: false,
            showAllFields: true,
            organize: "31",
            unit: "",
            country: "",
            isChecked: false,
            repeat: false,
            tableCellNum: 0,
        };

        this.fieldOptions = [
            {
                key: "table",
                text: "Table",
                iconProps: { iconName: "Table" },
            },
        ];
    }
    public componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const unit_url = params.get("measure");
        const country_url = params.get("coo");
        if (unit_url != null && country_url != null) {
            this.setState({ unit: unit_url });
            this.setState({ country: country_url });
        } else {
            this.setState({ unit: "" });
            this.setState({ country: "" });
        }
    }
    private fieldsButtonStyles: IButtonStyles = {
        menuIcon: {
            // This is for hiding the chevron icon, please note that we can't use display: none because
            // it will be overwritten by ms-Icon class.
            width: 0,
            height: 0,
            margin: 0,
            overflow: "hidden",
        },
    };

    public componentDidUpdate(prevProps) {
        const { currentDocument } = this.props;
        if (prevProps.currentDocument?.name !== currentDocument?.name) {
            // Clear states when switching document.
            this.clearStates();
        }
        if (this.props.fileName && !this.state.repeat) {
            this.setState({ repeat: true }, () => {
                const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
                const apis = `${serverUrl}/getTableCellNum/${this.props.fileName}`;
                getWithAutoRetry(apis).then((res) => {
                    if (res.data.code === 200) {
                        this.setState({ tableCellNum: res.data.tableCellNum });
                    }
                });
                //至後端讀取labels.json，取得fieldKey的值和column的值
                const api = `${serverUrl}/getFieldKey/${this.props.fileName}`;
                getWithAutoRetry(api).then((res) => {
                    const fieldKey: string = res.data.fieldKey;
                    const tableType: TableType = TableType.dynamic;
                    const headerType: HeaderType = HeaderType.column;
                    const columns: Array<string> = res.data.column as Array<string>;
                    this.props.addTableField({ fieldKey, tableType, headerType, columns });
                });
            });
        }
    }
    private clearStates = () => {
        const { setHideInlineLabelMenu, setIsTablePaneOpen } = this.props;
        setHideInlineLabelMenu(false);
        this.setState({
            isFieldCalloutOpen: false,
            createFieldType: undefined,
            tableFieldKey: undefined,
        });
        setIsTablePaneOpen(false);
    };

    private getDocumentLabels = (): Label[] => {
        const { currentDocument, labels } = this.props;
        if (currentDocument) {
            return labels[currentDocument.name] || [];
        } else {
            return [];
        }
    };

    private getTableLabels = (fieldKey: string): { [labelName: string]: Label } => {
        const labels = this.getDocumentLabels().filter((label) => getFieldKeyFromLabel(label) === fieldKey);
        return labels.reduce((obj, item) => ({ ...obj, [item.label]: item }), {});
    };
    // private postTableLabels = (fieldKey: string): { [labelName: string]: Label } => {
    //     const labels = this.getDocumentLabels().filter((label) => getFieldKeyFromLabel(label) === fieldKey);

    //     return labels.reduce((obj, item) => ({ ...obj, [item.label]: item }), {});
    // };

    private getTableDefinition = (fieldKey: string): ObjectField => {
        const { fields, definitions } = this.props;
        const field = fields.find((field) => field.fieldKey === fieldKey);

        if (field!.fieldType === FieldType.Array) {
            const { itemType } = field as ArrayField;
            return definitions[itemType];
        } else {
            const { fields } = field as ObjectField;
            const { fieldType } = fields[0]; // currently only support ObjectField whose fields are having all identical filedType.
            return definitions[fieldType];
        }
    };

    private handleCreateField = (value: string) => {
        if (!value) {
            return;
        }
        const newField: Field = {
            fieldKey: value,
            fieldType: this.state.createFieldType!,
            fieldFormat: FieldFormat.NotSpecified,
        };
        this.props.addField(newField);
    };

    // private handleCreateTableField = async (fieldKey: string, tableType: TableType, headerType: HeaderType) => {
    //     await this.props.addTableField({ fieldKey, tableType, headerType });
    // };

    private handleRenameTableField = async (
        tableFieldKey: string,
        fieldKey: string,
        newName: string,
        fieldLocation: FieldLocation
    ) => {
        await this.props.renameTableField({ tableFieldKey, fieldKey, newName, fieldLocation });
    };

    private handleDeleteTableField = async (tableFieldKey: string, fieldKey: string, fieldLocation: FieldLocation) => {
        await this.props.deleteTableField({ tableFieldKey, fieldKey, fieldLocation });
    };

    private handleInsertTableField = async (
        tableFieldKey: string,
        fieldKey: string,
        index: number,
        fieldLocation: FieldLocation
    ) => {
        await this.props.insertTableField({ tableFieldKey, fieldKey, index, fieldLocation });
    };

    private handleDeleteTableLabel = async (label: string) => {
        const res = await this.props.deleteLabelByLabel(label);
        this.setState({ tableCellNum: res.payload[this.props.fileName].length });
    };

    private handleCreateFieldClick = (type: FieldType) => {
        this.setState({ isFieldCalloutOpen: true, createFieldType: type });
    };

    private handleCreateFieldDismiss = () => {
        this.setState({ isFieldCalloutOpen: false, createFieldType: undefined });
    };

    private handleTablePaneClose = () => {
        const { setIsTablePaneOpen, setHideInlineLabelMenu } = this.props;

        setHideInlineLabelMenu(false);
        setIsTablePaneOpen(false);
        this.setState({ tableFieldKey: undefined });
    };

    // private handleCreateTableModalClose = () => {
    //     this.setState({ isCreateTableModalOpen: false });
    // };

    private handleAssignLabel = async (labelName: string) => {
        const res = await this.props.assignLabel(labelName);
        if (this.state.tableCellNum !== res.payload[this.props.fileName].length) {
            this.setState({ tableCellNum: res.payload[this.props.fileName].length });
        }
    };

    // private makeFieldsMenu = () => {
    //     return {
    //         items: this.fieldOptions.map((option) => ({
    //             ...option,
    //             iconProps: option.iconProps,
    //             onClick:
    //                 option.key === "table"
    //                     ? this.onCreateTableClick
    //                     : () => this.handleCreateFieldClick(option.fieldType!),
    //         })),
    //         directionalHint: DirectionalHint.bottomRightEdge,
    //         onMenuOpened: this.handleCreateFieldDismiss,
    //     };
    // };

    // private onCreateTableClick = () => {
    //     this.setState({ isCreateTableModalOpen: true });
    // };

    private onFieldFilterClick = () => {
        this.setState((prevState) => ({
            showAllFields: !prevState.showAllFields,
        }));
    };

    private onGetCreateFieldErrorMessage = (value: string) => {
        const { fields } = this.props;
        const isDuplicate = fields.some((field) => field.fieldKey === value);

        if (isDuplicate) {
            return "The field already exists.";
        } else {
            return undefined;
        }
    };

    private handleItemMouseEnter = (labelName: string) => {
        const { hoveredLabelName, setHoveredLabelName } = this.props;
        if (hoveredLabelName !== labelName) {
            setHoveredLabelName(labelName);
        }
    };

    private handleItemMouseLeave = () => {
        this.props.setHoveredLabelName("");
    };

    private handleTablePaneOpen = (field: Field) => {
        this.props.setIsTablePaneOpen(true);
        this.setState({ tableFieldKey: field.fieldKey });
    };

    private handleInputChangeOrganize = (event) => {
        this.setState({
            organize: event.target.value,
        });
    };

    private handleInputChangeUnit = (event) => {
        this.setState({
            unit: event.target.value,
        });
    };

    private handleInputChangeCountry = (event) => {
        this.setState({
            country: event.target.value,
        });
    };

    private handleButtonClick = (tableFieldKey, fileName) => {
        const serverUrl = process.env.REACT_APP_SERVER_SITE_URL;
        const apis = `${serverUrl}/export`;
        postWithAutoRetry(
            apis,
            {
                Organize: this.state.organize,
                Unit: this.state.unit,
                Country: this.state.country,
                Table: this.getTableLabels(tableFieldKey),
                fileName: fileName,
            },
            { responseType: "arraybuffer" }
        ).then((data: any) => {
            toast.success("檔案已匯出", { autoClose: 100, position: toast.POSITION.TOP_CENTER });
            let a = document.createElement("a");
            let blob = new Blob([data.data]);
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName + ".xls";
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };
    private handleChangeCheck = (event) => {
        this.setState({ isChecked: event.target.checked });
    };

    private FullScreenLock = () => (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    marginTop: "20px",
                    zIndex: 100,
                    backgroundColor: "#FFFFFF",
                    height: "24px",
                    padding: "12px",
                }}
            >
                <a href="label" style={{ color: "black", textDecoration: "none" }}>
                    <b>連結已失效，請重新登入...</b>
                </a>
            </div>
        </div>
    );

    private handleDescription = () => {
        this.setState({ isDescriptionModalOpen: true });
    };

    private handleDescriptionModalClose = () => {
        this.setState({ isDescriptionModalOpen: false });
    };

    // private noop = () => {};
    public render() {
        const { fields, isTablePaneOpen, fileName } = this.props;
        const { tableFieldKey, showAllFields, isFieldCalloutOpen } = this.state;
        const tableField = fields.find((field) => field.fieldKey === tableFieldKey);
        const cancelIcon: IIconProps = { iconName: "Cancel" };
        const theme = getTheme();
        const contentStyles = mergeStyleSets({
            container: {
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "stretch",
            },
            header: [
                theme.fonts.xLargePlus,
                {
                    flex: "1 1 auto",
                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                    color: theme.palette.neutralPrimary,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: FontWeights.semibold,
                    padding: "12px 12px 14px 24px",
                },
            ],
            heading: {
                color: theme.palette.neutralPrimary,
                fontWeight: FontWeights.semibold,
                fontSize: "inherit",
                margin: "0",
            },
            body: {
                flex: "4 4 auto",
                padding: "0 24px 24px 24px",
                overflowY: "hidden",
                lineHeight: "26px",
                selectors: {
                    p: { margin: "0", marginBottom: "10px" },
                    "p:first-child": { marginTop: 0 },
                    "p:last-child": { marginBottom: 0 },
                },
            },
        });
        const iconButtonStyles: Partial<IButtonStyles> = {
            root: {
                color: theme.palette.neutralPrimary,
                marginLeft: "auto",
                marginTop: "4px",
                marginRight: "2px",
            },
            rootHovered: {
                color: theme.palette.neutralDark,
            },
        };

        const dragOptions: IDragOptions = {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
        };

        const widths = "100%";
        return (
            <Stack className="label-pane" grow={1}>
                {isTablePaneOpen ? (
                    <Stack className="table-pane" grow={1}>
                        {!this.props.isValid && this.FullScreenLock()}
                        <Stack
                            className="table-pane-header"
                            horizontal
                            style={{ alignItems: "center", justifyContent: "space-between" }}
                        >
                            <div style={{ display: "flex", marginLeft: "5px" }}>
                                <PrimaryButton
                                    onClick={this.handleDescription}
                                    style={{
                                        fontSize: "12px",
                                        width: "12px",
                                        height: "12px",
                                        padding: "0",
                                        backgroundColor: "transparent",
                                        color: "black",
                                        minWidth: "0px",
                                        border: "none",
                                    }}
                                >
                                    <i className="fas fa-info-circle" style={{ color: "rgba(0, 171, 255, 1)" }}></i>
                                </PrimaryButton>
                                <h5 style={{ marginLeft: "5px" }}>{tableFieldKey}</h5>
                            </div>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <Modal
                                    isOpen={this.state.isDescriptionModalOpen}
                                    onDismiss={this.handleDescriptionModalClose}
                                    isBlocking={false}
                                    className="noOverFlow"
                                    dragOptions={dragOptions}
                                >
                                    <div className={contentStyles.header}>
                                        <h4 className={contentStyles.heading}>
                                            說明&nbsp;<i className="far fa-info-circle"></i>
                                        </h4>
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={this.handleDescriptionModalClose}
                                        />
                                    </div>
                                    <div className={contentStyles.body}>
                                        <div
                                            style={{
                                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                                padding: "12px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-wrench" style={{ marginRight: "10px" }}></i>
                                                操作方式
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                左邊區域為文件瀏覽區，右邊區域為系統自動抓取文件資料的表格，若有欄位需從文件中新增至表格，只需點擊文件文字底色為
                                                <b style={{ color: "#f90" }}>黃色</b>
                                                的區塊，若該區塊變為<b style={{ color: "green" }}>綠色框</b>
                                                代表已選擇該區塊，此時只要點選右側表格任意的格子，即可將所選取的文字帶入該格中。倘該格中已存在其他文字時，則會直接與當中的文字合併在一起。或是可先於該格右上方點選&quot;X&quot;
                                                清除內容後，再將新的文字內容填入
                                                <br />
                                                <em style={{ color: "#f00", marginBottom: "10px" }}>
                                                    *若要一次選擇多個區塊，可以按住點擊鍵不放，選取想要的區塊範圍
                                                </em>
                                                <br />
                                                <em style={{ color: "#f00", marginBottom: "10px" }}>
                                                    *若要取消選取，請在綠色框再點選一次，即可消除選取
                                                </em>
                                            </p>
                                            <img
                                                src="images/customModels/add_content.png"
                                                width={widths}
                                                alt="add_content"
                                            />
                                        </div>
                                        <br />
                                        <div
                                            style={{
                                                backgroundColor: "rgba(0, 152, 0, 0.2)",
                                                padding: "12px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-download" style={{ marginRight: "10px" }}></i>
                                                匯出檔案
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                將目前標記結果以表格格式匯出，若&quot;納辦&quot;、&quot;單位&quot;、&quot;產地&quot;在下方標記結果內為空格時，則會使用上方所填寫之文字代入檔案中
                                            </p>
                                            <img src="images/customModels/export.png" width={widths} alt="export" />
                                        </div>
                                        <br />
                                        <div
                                            style={{
                                                backgroundColor: "rgba(0, 128, 255, 0.29)",
                                                padding: "12px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-eraser" style={{ marginRight: "10px" }}></i>
                                                清除貨物名稱
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                若自動識別的貨物名稱不符合目前製單需求，點選該按鈕即可清除表格內的所有貨物名稱，再以手動方式重新選取正確的貨物名稱
                                            </p>
                                            <img
                                                src="images/customModels/clear_description.png"
                                                width={widths}
                                                alt="clear_description"
                                            />
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px", marginTop: "10px" }}>
                                                <i className="fas fa-reply" style={{ marginRight: "10px" }}></i>
                                                還原初始表格
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                當修改多個欄位內容修改到一半才發現多個地方有錯誤，導致表格混亂複雜，點選該按鈕即可還原初始表格
                                            </p>
                                            <img
                                                src="images/customModels/recycle_table.png"
                                                width={widths}
                                                alt="recycle_table"
                                            />
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px", marginTop: "10px" }}>
                                                <i className="fas fa-table" style={{ marginRight: "10px" }}></i>
                                                清除表格
                                            </b>
                                            <p style={{ marginTop: "5px" }}>
                                                當系統自動抓取的所有欄位內容皆不符合要求，點選該按鈕即可清除所有表格欄位的內容
                                            </p>
                                            <img
                                                src="images/customModels/clear_all.png"
                                                width={widths}
                                                alt="clear_all"
                                            />
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-pencil" style={{ marginRight: "10px" }}></i>
                                                總項次數
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                為目前識別出的文件總項次數，若需增減為指定項次數量，可以直接在文字框中修改數字，並點擊&quot;更改&quot;的按鈕，即可更新表格的總項次數
                                                <br />
                                                <em style={{ color: "#f00" }}>*總項次數範圍介於1~1000</em>
                                            </p>
                                            <img
                                                src="images/customModels/modify_item.png"
                                                width={widths}
                                                alt="modify_item"
                                            />
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-exchange" style={{ marginRight: "10px" }}></i>
                                                數字轉換
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                若要讓表格匯出時的單價、金額欄位沒有其他除了數字內容，勾選數字轉換即可去除數字以外的內容，且將小數簡化或變為整數
                                            </p>
                                            <img
                                                src="images/customModels/number_conversion.png"
                                                width={widths}
                                                alt="number_conversion"
                                            />
                                        </div>
                                        <br />
                                        <div
                                            style={{
                                                backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                padding: "12px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <div style={{ marginTop: "10px" }}></div>
                                            <b style={{ fontSize: "20px" }}>
                                                <i className="fas fa-eraser" style={{ marginRight: "10px" }}></i>
                                                清除欄位內容
                                            </b>
                                            <p style={{ marginTop: "10px" }}>
                                                若要清除某一欄位的連續幾個項次內容，可選擇欄位名稱並輸入項次起訖區間，再點擊&quot;清除&quot;的按鈕，即可清除指定項次欄位之內容
                                            </p>
                                            <img
                                                src="images/customModels/clear_item.png"
                                                width={widths}
                                                alt="clear_item"
                                            />
                                        </div>
                                    </div>
                                </Modal>
                                <Stack horizontal tokens={{ childrenGap: "15px" }} style={{ alignItems: "center" }}>
                                    <Stack.Item style={{ display: "flex", alignItems: "center" }}>
                                        <label htmlFor="organize">
                                            <b>納辦：</b>
                                        </label>
                                        <TextField
                                            id="organize"
                                            name="organize"
                                            value={this.state.organize}
                                            onChange={this.handleInputChangeOrganize}
                                            styles={{ fieldGroup: { height: 25, width: 100 } }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item style={{ display: "flex", alignItems: "center" }}>
                                        <label htmlFor="unit">
                                            <b>單位：</b>
                                        </label>
                                        <TextField
                                            id="unit"
                                            name="unit"
                                            value={this.state.unit}
                                            onChange={this.handleInputChangeUnit}
                                            styles={{ fieldGroup: { height: 25, width: 100 } }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item style={{ display: "flex", alignItems: "center" }}>
                                        <label htmlFor="country">
                                            <b>產地：</b>
                                        </label>
                                        <TextField
                                            id="country"
                                            name="country"
                                            value={this.state.country}
                                            onChange={this.handleInputChangeCountry}
                                            styles={{ fieldGroup: { height: 25, width: 100 } }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <PrimaryButton
                                            onClick={() => this.handleButtonClick(tableFieldKey!, fileName)}
                                            style={{
                                                backgroundColor: "green",
                                                minWidth: "70px",
                                                height: "30px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <i className="fal fa-download" style={{ marginRight: "5px" }}></i>
                                            <b>匯出檔案</b>
                                        </PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack grow={1}>
                            <Stack className="table-label-item-container" grow={1}>
                                {tableFieldKey && (
                                    <TableLabelItem
                                        field={tableField!}
                                        tableLabels={this.getTableLabels(tableFieldKey!)}
                                        definition={this.getTableDefinition(tableFieldKey!)}
                                        onDeleteField={this.handleDeleteTableField}
                                        onInsertField={this.handleInsertTableField}
                                        onRenameField={this.handleRenameTableField}
                                        onDeleteLabel={this.handleDeleteTableLabel}
                                        onClickCell={this.handleAssignLabel}
                                        onItemMouseEnter={this.handleItemMouseEnter}
                                        onItemMouseLeave={this.handleItemMouseLeave}
                                        fileName={fileName}
                                        isChecked={this.state.isChecked}
                                        onCheckChanged={this.handleChangeCheck}
                                        getAndSetLabels={this.props.getAndSetLabels}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <>
                        <Stack
                            className="label-pane-command-bar"
                            horizontal
                            horizontalAlign="space-between"
                            verticalAlign="center"
                        >
                            <div>{/* TODO: put item count UI here */}</div>
                            <Stack horizontal>
                                {/* <IconButton
                                    id="add-button"
                                    title="Add field"
                                    styles={this.fieldsButtonStyles}
                                    iconProps={{ iconName: "Add" }}
                                    menuProps={this.makeFieldsMenu()}
                                /> */}
                                <IconButton
                                    styles={{ root: { display: "none" } }}
                                    iconProps={{ iconName: showAllFields ? "Filter" : "ClearFilter" }}
                                    onClick={this.onFieldFilterClick}
                                />
                            </Stack>
                        </Stack>
                        <Stack grow={1} style={{ height: 0 }}>
                            <LabelList onTablePaneOpen={this.handleTablePaneOpen} />
                        </Stack>
                        {isFieldCalloutOpen && (
                            <FieldCallout
                                width={268}
                                target={"#add-button"}
                                onCreateField={this.handleCreateField}
                                onDismiss={this.handleCreateFieldDismiss}
                                onGetErrorMessage={this.onGetCreateFieldErrorMessage}
                                directionalHintFixed
                                directionalHint={DirectionalHint.bottomRightEdge}
                            />
                        )}
                    </>
                )}
                {/* <CreateTableModal
                    isOpen={isCreateTableModalOpen}
                    onClose={this.handleCreateTableModalClose}
                    // onCreateField={this.handleCreateTableField}
                    onGetNameErrorMessage={this.onGetCreateFieldErrorMessage}
                /> */}
            </Stack>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    currentDocument: state.documents.currentDocument,
    fields: state.customModel.fields,
    labels: state.customModel.labels,
    definitions: state.customModel.definitions,
    location: state.router.location,
    hoveredLabelName: state.canvas.hoveredLabelName,
});
const mapDispatch = {
    addField,
    addTableField,
    switchSubType,
    deleteField,
    deleteTableField,
    renameField,
    renameTableField,
    insertTableField,
    deleteLabelByField,
    deleteLabelByLabel,
    assignLabel,
    setHoveredLabelName,
    setHideInlineLabelMenu,
    setFields,
};

const connector = connect(mapState, mapDispatch);

export default connector(LabelPane);
